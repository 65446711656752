document.body.addEventListener("htmx:beforeRequest", (event) => {
    if (event.detail.boosted || event.detail.requestConfig.headers['HX-Boosted']) {
        NProgress.start();
    }
});

document.body.addEventListener("htmx:afterRequest", (event) => {
    if (event.detail.boosted || event.detail.requestConfig.headers['HX-Boosted']) {
        NProgress.done();
        pageInit();
        dReady();
        beaverLoad();
        accountManagerReady()
    }
});

document.body.addEventListener("htmx:afterSwap", () => {
    // For tabs, when they are ajax'd on a post, we need to refresh to fix the style.
    // jQuery UI tabs seems to start adding classes to everything like ul and li tags,
    // but just doesn't add them to the div.
    // Also, it seems weird on the search page doing multiple searches will break.
    // We will update these with proper hx-get and hx-post, this is temporary.
    // Yes, I know this sucks. No, You can't reduce the time any lower
    if ($('.tabs').length > 0) {
        setTimeout(function () {
            $('.tabs').tabs("destroy")
            $('.tabs').tabs()
        }, 25);
    }
});

document.body.addEventListener("htmx:beforeHistorySave", () => {
    NProgress.remove();
    // Before we save, remove all chosen containers since they'll get regenerated
    document.querySelectorAll('.chosen-container').forEach(function (item) {
        item.remove();
    })
});

document.body.addEventListener('htmx:historyRestore', () => {
    pageInit();
    dReady();
    beaverLoad();
    $("select").chosen({ width: "100%" });

    // On restore remove the expanded action. we can't do this on beforeSave save since it breaks actions when you go to them. I think this is a timing issue.
    document.querySelectorAll('tr.expanded').forEach(function (item) {
        item.classList.remove('expanded');
        item.nextElementSibling.remove();
    })
})

document.body.addEventListener('clear-history', () => {
    localStorage.removeItem("htmx-history-cache");
})

document.addEventListener('htmx:responseError', (event) => {
    const contentType = event.detail.xhr.getResponseHeader("Content-Type");
    if (!contentType.includes('application/json')) {
        return;
    }

    const responseData = event.detail.xhr.responseText;
    if (responseData === undefined || responseData === '') {
        return;
    }

    const parsedResponse = JSON.parse(responseData);

    if (parsedResponse.message !== undefined && parsedResponse.message !== '') {
        toastr.error(parsedResponse.message);
    }

    if (parsedResponse.errors !== undefined) {
        if (parsedResponse.errors instanceof Array) {
            parsedResponse.errors.forEach((errorMessage) => toastr.error(errorMessage));

            return;
        }

        if (parsedResponse.errors instanceof Object) {
            for (let objectKey in parsedResponse.errors) {
                toastr.error(parsedResponse.errors[objectKey]);
            }

            return;
        }

        toastr.error(parsedResponse.errors);
    }
});
